<template>
  <div>
    <table class="table table-hover"
           v-if="collaborator.hasPerm('VIEW_BUDGET', budget.office.reference)">
      <thead>
      <tr>
        <th>
          <router-link :to="{name: 'Budget', params: {id: budget.id}}">Marketing budget {{ budget.year }} voor
            kantoor {{ budget.office.reference }}
          </router-link>
        </th>
        <th style="text-align: right;">
          Jaarbudget<br> &euro; {{ budget.predetermined_budget|currency }}
        </th>
        <th :class="{'txt-danger': budget.reserved_budget < 0}" style="text-align: right;">
          Gereserveerd in potjes<br> &euro; {{ budget.reserved_budget|currency }}
        </th>
        <th :class="{'txt-danger': budget.unused_budget < 0}" style="text-align: right;">
          Resterend saldo<br> &euro; {{ budget.unused_budget|currency }}
        </th>
        <th>
          <a @click="toggleVisible" class="btn btn-default btn-xs">Toon/verberg details</a>
        </th>
      </tr>
      <tr v-if="isVisible">
        <th>Strategisch niveau</th>
        <th class="text-right">Jaarbudget</th>
        <th class="text-right">Gereserveerd in potjes</th>
        <th class="text-right">Eindsaldo</th>
      </tr>
      </thead>
      <tbody v-if="isVisible">
      <tr :key="level.id" v-for="level in budget.strategic_levels">
        <td>
          {{ level.type.name }}
        </td>
        <td class="text-right">
          {{ level.predetermined_budget | currency }}
        </td>
        <td class="text-right">
          {{ level.reserved_budget | currency }}
        </td>
        <td class="text-right">
          {{ level.unused_budget | currency }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BudgetOverviewPanel',
  props: ['budget'],
  data () {
    return {
      isVisible: false
    }
  },
  computed: {
    ...mapState('account', ['user']),
    collaborator () {
      return this.user.collaborator
    }
  },
  methods: {
    toggleVisible () {
      this.isVisible = !this.isVisible
    }
  }
}
</script>

<style scoped>

</style>
